const logotext = "JACKSON";
const meta = {
    title: "Jackson Howden",
    description: "I'm Jackson Howden, an aspiring accountant, designer and front end developer working out of Edmonton, AB",
};

const introdata = {
    title: "I’m Jackson Howden",
    animated: {
        first: "I Love Design",
        second: "I Play Handball",
        third: "I Love Sports",
    },
    description: "Take a look around and if you think that we are a match made in heaven hit the button below",
    your_img_url: "https://iili.io/Jah2oox.jpg",
};

const dataabout = {
    title: "Abit About My Self",
    aboutme: "I am a dynamic and skilled Data Analyst with a Bachelor of Commerce from MacEwan University, graduating with a 3.4 GPA. My experience includes a role at Quantum Recreation where I specialized in interpreting complex datasets, providing actionable insights, and understanding patterns and trends. I have also demonstrated leadership and operational management skills as a Dock Manager and Supervisor at Twin Anchors Houseboats, where I managed daily operations and staff, ensuring quality and efficiency. My background in landscaping showcases my physical strength and versatility. I am fluent in French and excel in both independent and team environments, underlining my excellent communication skills. Additionally, I am an accomplished handball player, captaining national teams and participating in international tournaments, reflecting my strong team spirit and dedication.",
};
const worktimeline = [
    {
        jobtitle: "Business and Data Analyst",
        where: "Quantum Recreation, Edmonton",
        date: "2023-2024",
    },
    {
        jobtitle: "Dock Manager",
        where: "Twin Anchors Houseboats, Sicamous ",
        date: "2022",
    },
    {
        jobtitle: "Accounting Student",
        where: "Grant MacEwan Univeristy, Edmonton",
        date: "2018-2023",
    },
];

const skills = [{
        name: "Python",
        value: 90,
    },
    {
        name: "Accounting",
        value: 80,
    },
    {
        name: "Javascript",
        value: 30,
    },
    {
        name: "React",
        value: 30,
    },
    {
        name: "Sports",
        value: 85,
    },
];

const services = [{
        title: "Jersey Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Mobile Apps",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Wordpress Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
];

const dataportfolio = [{
        img: "https://iili.io/Ja0Rihu.png",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://iili.io/Ja07vJ2.png",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },

    {
        img: "https://picsum.photos/400/600/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/550/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
];

const contactConfig = {
    YOUR_EMAIL: "jhowden1234@gmail.com",
    YOUR_FONE: "(587)335-2494",
    description: "Call my ass",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_5tl5cpf",
    YOUR_TEMPLATE_ID: "template_7vucpqj",
    YOUR_USER_ID: "Arjy_sNc_kn0Ns0iM",
};

const socialprofils = {
    github: "https://github.com/JokesonH",
    instagram: "https://www.instagram.com/jackson_howden/",
    linkedin: "https://www.linkedin.com/in/jackson-howden-4b13b6173/",
    twitter: "https://twitter.com/Jokeson_",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};